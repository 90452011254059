import type { Draft, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { Shape } from "@somewear/api";
import type { IContactForDisplay } from "@somewear/model";
import { createActionSet, resetState, Sentry, SWL_COLOR_BLUE_2023 } from "@somewear/model";
import type { IShape } from "@somewear/shapes";
import { shapeActions } from "@somewear/shapes";
import { waypointActions } from "@somewear/waypoint";
import { bearing as turfBearing, circle as turfCircle, distance as turfDistance } from "@turf/turf";
import type { Feature, Point, Polygon } from "geojson";
import type { WritableDraft } from "immer/dist/types/types-external";
import { cloneDeep } from "lodash";
import type { LngLatLike } from "mapbox-gl";
import { type Observer } from "rxjs/internal/types";

import type { IKmlParserResponse } from "./KmlParser";
import type { LayerState } from "./layer.state";
import type { MapLayer, MapLayers } from "./layers.model";
import { getMapLayerKey, MapLayerType } from "./layers.model";
import { MapLayersStore } from "./layers.store";
import { MapModalTypes } from "./mapLayers.model";

const mapLayerAdapter = createEntityAdapter<MapLayer>({
	selectId: (layer) => getMapLayerKey(layer),
	sortComparer: (a, b) => {
		return a.order - b.order;
	},
});

interface IMeasureCoordinate {
	timestamp: number;
	coordinate: LngLatLike;
}

const measureCoordinateAdapter = createEntityAdapter<IMeasureCoordinate>({
	selectId: (coord) => coord.timestamp,
	sortComparer: (a, b) => {
		return a.timestamp - b.timestamp;
	},
});

export interface MapLayersState {
	layers: EntityState<MapLayer>;
	measureCoordinates: EntityState<IMeasureCoordinate>;
	// count: number;
	editAsset?: IContactForDisplay;
	newLayerId: string | undefined;
	visibleModal?: MapModalTypes;
	isFileDropOverlayVisible: boolean;
	selectedShapeId?: string;
}

const initialState: MapLayersState = {
	layers: mapLayerAdapter.getInitialState(),
	measureCoordinates: measureCoordinateAdapter.getInitialState(),
	newLayerId: undefined,
	isFileDropOverlayVisible: false,
	visibleModal: undefined,
	editAsset: undefined,
};

/*const getLayer = function (layers: MapLayers, id: string) {
	return layers.find((layer) => {
		return layer.id === id;
	});
};*/

function updateStore(state: Draft<MapLayersState>) {
	const layers = selectAllLayers(state).filter((it) => it.type === MapLayerType.Kml);

	const obs: Observer<MapLayers | undefined> = {
		next: (value) => {},
		error: (err) => {
			Sentry.captureException(err);
			console.error(err);
		},
		complete: () => {},
	};

	MapLayersStore.write$(layers).subscribe(obs);
}

export const mapLayersActions = {
	fetch: createActionSet<MapLayer, MapLayer>("mapLayers/fetch"),
	add: createActionSet<IKmlParserResponse, MapLayers>("mapLayers/add"),
};

export const { selectAll: _selectAllMapLayers, selectById: selectMapLayerByKey } =
	mapLayerAdapter.getSelectors((state: LayerState) => state.mapLayers.layers);

export const selectSortedMapLayers = createSelector([_selectAllMapLayers], (layers) => {
	return [...layers].sort((a, b) => getMapLayerKey(a).localeCompare(getMapLayerKey(b)));
});

function selectAllLayers(state: Draft<MapLayersState>): MapLayers {
	return mapLayerAdapter.getSelectors().selectAll(state.layers);
}

function selectLayerByKey(state: Draft<MapLayersState>, key: string): MapLayer | undefined {
	return mapLayerAdapter.getSelectors().selectById(state.layers, key);
}

export const { selectAll: selectAllMeasureCoordinates } = measureCoordinateAdapter.getSelectors(
	(state: LayerState) => state.mapLayers.measureCoordinates,
);

export const mapLayerFactory = (
	_layer: MapLayer,
	result: IKmlParserResponse,
	index?: number,
): MapLayer => {
	const layer = cloneDeep(_layer);
	layer.id = _layer.id;
	layer.fileName = _layer.fileName;
	layer.index = index ?? _layer.index;
	layer.data = result.data;
	layer.overlays = result.overlays;
	layer.styles = result.styles;
	layer.styleMaps = result.styleMaps;
	layer.links = result.networkLinks;
	layer.srcLink = result.srcLink;
	layer.state.loading = false;
	layer.uploadDate = Intl.DateTimeFormat("en-US", {
		year: "numeric",
		month: "short",
		day: "2-digit",
	}).format(new Date());
	return layer;
};

export const mapLayersSlice = createSlice({
	name: "mapLayers",
	initialState,
	reducers: {
		init(state) {},
		upsertMapLayers(state, action: PayloadAction<MapLayers>) {
			if (action.payload.isEmpty()) return;
			mapLayerAdapter.upsertMany(state.layers, action.payload);
			updateStore(state);
		},
		clearNewLayerId(state) {
			state.newLayerId = undefined;
		},
		// Add a new map layer to the collection.
		addLayer(state, action: PayloadAction<string>) {
			const length = selectAllLayers(state).length;
			const newLayer: MapLayer = {
				fileName: action.payload,
				id: `layer-${length}`,
				index: 0,
				order: length + 1,
				uploadDate: "",
				state: {
					loading: true,
					visible: true,
				},
				type: MapLayerType.Kml,
			};

			state.newLayerId = newLayer.id;
			mapLayerAdapter.upsertOne(state.layers, newLayer);
		},

		handleLayersUnmount(state) {
			state.visibleModal = undefined;
		},

		toggleMapModal(state, action: PayloadAction<MapModalTypes>) {
			state.visibleModal = state.visibleModal === action.payload ? undefined : action.payload;
		},

		showMapModal(state, action: PayloadAction<MapModalTypes>) {
			state.visibleModal = action.payload;
		},

		hideMapModal(state) {
			state.visibleModal = undefined;
		},

		showFileUploadOverlay(state, action: PayloadAction<boolean>) {
			state.isFileDropOverlayVisible = action.payload;
		},

		selectShapeId(state, action: PayloadAction<string>) {
			state.selectedShapeId = action.payload;
		},

		setEditAsset(state, action: PayloadAction<IContactForDisplay>) {
			state.editAsset = action.payload;
			state.visibleModal = MapModalTypes.EditAsset;
		},

		clearEditAsset(state) {
			state.editAsset = undefined;
			state.visibleModal = undefined;
		},

		// Change the visibility of a specific layer/
		changeVisibility(state, action: PayloadAction<string>) {
			selectAllLayers(state).forEach((_layer) => {
				if (getMapLayerKey(_layer) === action.payload) {
					const layer = cloneDeep(_layer);
					layer.state.visible = !layer.state.visible;
					mapLayerAdapter.upsertOne(state.layers, layer);
				}
			});

			updateStore(state);
		},

		// Move a layer above/below another layer.
		moveLayer(
			state,
			action: PayloadAction<{ fromKey: string; toKey: string; above: boolean }>,
		) {
			const fromLayerOrder = selectLayerByKey(state, action.payload.fromKey)?.order || 0;
			const toLayerOrder = selectLayerByKey(state, action.payload.toKey)?.order || 0;
			const downward = toLayerOrder > fromLayerOrder;
			const newLayerOrder = downward
				? action.payload.above
					? toLayerOrder - 1
					: toLayerOrder
				: action.payload.above
					? toLayerOrder
					: toLayerOrder + 1;

			selectAllLayers(state).forEach((_layer) => {
				const layer = cloneDeep(_layer);

				if (layer.order === fromLayerOrder) {
					layer.order = newLayerOrder;
				} else if (
					downward &&
					layer.order > fromLayerOrder &&
					layer.order <= newLayerOrder
				) {
					layer.order -= 1;
				} else if (
					!downward &&
					layer.order >= newLayerOrder &&
					layer.order < fromLayerOrder
				) {
					layer.order += 1;
				}

				mapLayerAdapter.upsertOne(state.layers, layer);
			});

			updateStore(state);
		},

		removeAllLayers(state, action: PayloadAction<MapLayerType>) {
			const layerIds = selectAllLayers(state)
				.filter((it) => it.type === action.payload)
				.map((it) => getMapLayerKey(it));

			mapLayerAdapter.removeMany(state.layers, layerIds);
			updateStore(state);
		},

		// Remove a specific layer.
		removeLayerById(state, action: PayloadAction<string>) {
			mapLayerAdapter.removeOne(state.layers, action.payload);
			updateStore(state);
		},

		// Remove a specific layer by name (used to clear errored layers)
		_removeLayerByName(state, action: PayloadAction<string>) {
			const layers = selectAllLayers(state);
			const layer = layers.find((layer) => layer.fileName === action.payload)!;
			mapLayerAdapter.removeOne(state.layers, getMapLayerKey(layer));
			updateStore(state);
		},

		// Rename a specific layer's file name.
		renameLayer(state, action: PayloadAction<{ key: string; newName: string }>) {
			selectAllLayers(state).forEach((_layer) => {
				if (getMapLayerKey(_layer) === action.payload.key) {
					// MapLayersStore.renameLayer(layer, action.payload.newName);

					const layer = cloneDeep(_layer);
					layer.fileName = action.payload.newName;
					mapLayerAdapter.upsertOne(state.layers, layer);
				}
			});

			updateStore(state);
		},

		// MEASURE COORDINATE REDUCERS

		addMeasureCoordinate(state, action: PayloadAction<IMeasureCoordinate>) {
			measureCoordinateAdapter.upsertOne(state.measureCoordinates, action.payload);
		},

		clearMeasureCoordinates(state) {
			measureCoordinateAdapter.removeAll(state.measureCoordinates);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(mapLayersActions.fetch.fulfilled, (state, action) => {
			mapLayerAdapter.upsertOne(state.layers, action.payload.data);
			updateStore(state);
		});
		builder.addCase(resetState, (state) => {
			mapLayerAdapter.removeAll(state.layers);
			updateStore(state);
		});
		builder.addCase(waypointActions.saveWaypoint.request, (state, action) => {
			if (state.visibleModal === MapModalTypes.Markers) {
				state.visibleModal = undefined;
			}
		});
		builder.addCase(shapeActions.createShape.rejected, (state, action) => {
			const shapeId = action.payload.requestId;
			const layerKey = `${shapeId}-0`;

			const layer = selectLayerByKey(state, layerKey);
			if (layer !== undefined) mapLayerAdapter.removeOne(state.layers, layerKey);
		});
		builder.addCase(shapeActions.createShape.fulfilled, (state, action) => {
			const shapeId = action.payload.requestId;
			const layerKey = `${shapeId}-0`;

			const layer = selectLayerByKey(state, layerKey);
			if (layer !== undefined) mapLayerAdapter.removeOne(state.layers, layerKey);

			if (action.payload.data.shape === undefined) return;
			convertShapesToLayers(state, [action.payload.data.shape]);
		});
		builder.addCase(shapeActions.getShapes.fulfilled, (state, action) => {
			const shapes = action.payload.data.shapesList;
			convertShapesToLayers(state, shapes);
		});
	},
});

export function convertShapeToLayer(shape: IShape): MapLayer | undefined {
	const layerId = `shape-${shape.id}`;
	const shapeId = shape.id;
	if (shape.circle?.center !== undefined) {
		const center = [shape.circle.center.longitude, shape.circle.center.latitude];

		const circleCoordinates = turfCircle(center, shape.circle.radius).geometry.coordinates;

		const vertex = circleCoordinates.first().first();
		const distance = turfDistance(center, vertex);
		const bearing = turfBearing(center, vertex);

		const circleFeature: Feature<Polygon> = {
			type: "Feature",
			properties: {
				...DRAWN_SHAPE_STYLE_PROPERTIES,
				id: shapeId,
				isDraw: true,
				isCircle: true,
				center: center,
				vertex: vertex,
				distance: distance,
				bearing: bearing,
			},
			geometry: {
				type: "Polygon",
				coordinates: circleCoordinates,
			},
		};

		const circlePointFeature: Feature<Point> = {
			type: "Feature",
			properties: {
				...DRAWN_SHAPE_STYLE_PROPERTIES,
				id: shapeId,
				isDraw: true,
				isCircle: true,
				center: center,
				vertex: vertex,
				distance: distance,
				bearing: bearing,
				radius: 15,
			},
			geometry: {
				type: "Point",
				coordinates: center,
			},
		};

		return {
			id: layerId,
			fileName: shape.name,
			index: 0,
			order: 0,
			data: {
				type: "FeatureCollection",
				features: [circleFeature, circlePointFeature],
			},
			shape: shape,
			state: {
				loading: false,
				visible: true,
			},
			type: MapLayerType.Shape,
		} as MapLayer;
	} else if (shape.polygon !== undefined) {
		const coordinates = shape.polygon.perimeterList.map((it) => {
			return [it.longitude, it.latitude];
		});

		const polygonFeature: Feature<Polygon> = {
			type: "Feature",
			properties: {
				...DRAWN_SHAPE_STYLE_PROPERTIES,
				isDraw: true,
				isRectangle: shape.polygon.type === Shape.Polygon.Type.RECTANGLE,
				id: shapeId,
			},
			geometry: {
				type: "Polygon",
				coordinates: [coordinates],
			},
		};

		return {
			id: layerId,
			fileName: shape.name,
			index: 0,
			order: 0,
			data: {
				type: "FeatureCollection",
				features: [polygonFeature],
			},
			shape: shape,
			state: {
				loading: false,
				visible: true,
			},
			type: MapLayerType.Shape,
		} as MapLayer;
	}
	console.warn("Unknown shape type", shape);
}

function convertShapesToLayers(state: WritableDraft<MapLayersState>, shapes: IShape[]) {
	shapes.forEach((shape) => {
		const newLayer = convertShapeToLayer(shape);
		if (newLayer !== undefined) mapLayerAdapter.upsertOne(state.layers, newLayer);
	});
}

export const DRAWN_SHAPE_STYLE_PROPERTIES = {
	"stroke-color": SWL_COLOR_BLUE_2023,
	"stroke-width": 1,
	"fill-color": SWL_COLOR_BLUE_2023,
	"fill-opacity": 0.08,
};

export const {
	addLayer,
	changeVisibility,
	moveLayer,
	removeLayerById,
	_removeLayerByName,
	renameLayer,
	showFileUploadOverlay,
	upsertMapLayers,
	clearNewLayerId,
	handleLayersUnmount,
	showMapModal,
	hideMapModal,
	toggleMapModal,
	clearMeasureCoordinates,
	addMeasureCoordinate,
	setEditAsset,
	clearEditAsset,
} = mapLayersSlice.actions;
